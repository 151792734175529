<template>
  <div class="app-bar">
    <v-app-bar
      app
      clipped-left
      elevate-on-scroll
      :dark="!showTabsMenu ? true : false"
      :color="!showTabsMenu ? 'primary' : 'grey lighten-2'"
      :fixed="showTabsMenu">
      <div v-if="user">
        <v-app-bar-nav-icon
          @click="drawer = true"
          v-if="isMobile || showTabsMenu"></v-app-bar-nav-icon>
      </div>

      <v-toolbar-title v-if="!showTabsMenu">
        Coruja Digital - Rádio Testemunhal App
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="showTabsMenu && !isMobile">
        <v-btn
          text
          tile
          :height="isMobile ? '56px' : '64px'"
          v-for="(item, index) in navbarItems"
          :key="index"
          @click="scrollTo(item.id)">
          {{ item.text }}
        </v-btn>
      </div>

      <v-menu v-if="isMobile && showTabsMenu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-subheader>MENU</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in navbarItems"
              :key="i">
              <v-list-item-content @click="scrollTo(item.id)">
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      app
      clipped
      :permanent="!isMobile && !showTabsMenu"
      :temporary="showTabsMenu"
      v-model="drawer"
      v-if="user">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img
              v-if="user && user.photoUrl"
              :src="user.photoUrl"></v-img>
            <v-img
              v-else
              src="@/assets/default_avatar.jpg"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item
          link
          @click="$router.push({ name: 'Profile' })">
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ user ? user.name : '' }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ user ? user.email : '' }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-menu-down</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list
        nav
        dense>
        <v-list-item-group
          v-model="drawerSelectedItem"
          color="primary">
          <v-list-item
            v-for="(item, i) in user.role === 'admin' ? menuItems : menuItemsOp"
            :key="i"
            @click.prevent="redirectTo(item.routeName)"
            :disabled="i == drawerSelectedItem">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider class="my-2"></v-divider>
        <v-list-item-group class="mt-8">
          <v-list-item @click="doLogout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import { auth, signOut } from '@/firebase/firebase'
  // import Snackbar from "@/components/Snackbar";

  export default {
    name: 'NavBar',
    components: {
      // Snackbar,
    },

    mounted() {
      //
    },

    data: () => ({
      drawer: false,
      drawerSelectedItem: 0,
      showTabsMenu: false,
      menuItemsOp: [
        {
          text: 'Dashboard',
          icon: 'mdi-view-dashboard-outline',
          routeName: 'Home'
        },

        {
          text: 'Campanhas',
          icon: 'mdi-newspaper',
          routeName: 'Campaigns'
        },
        {
          text: 'Painel de Censuras',
          icon: 'mdi-chart-box-outline',
          routeName: 'Panel'
        }
        // {
        //   text: "Review de Censuras",
        //   icon: "mdi-bug",
        //   routeName: "PanelReview",
        // },
      ],
      menuItems: [
        {
          text: 'Dashboard',
          icon: 'mdi-view-dashboard-outline',
          routeName: 'Home',
          requiresAdmin: false
        },
        {
          text: 'Usuários',
          icon: 'mdi-account-multiple',
          routeName: 'Users',
          requiresAdmin: true
        },
        {
          text: 'Campanhas',
          icon: 'mdi-newspaper',
          routeName: 'Campaigns',
          requiresAdmin: false
        },
        {
          text: 'Nova Campanha',
          icon: 'mdi-newspaper-plus',
          routeName: 'AddCampaign',
          requiresAdmin: true
        },
        {
          text: 'Painel de Censuras',
          icon: 'mdi-chart-box-outline',
          routeName: 'Panel',
          requiresAdmin: false
        },
        {
          text: 'Relatórios',
          icon: 'mdi-chart-pie',
          routeName: 'Reports'
        }
        // {
        //   text: "Review de Censuras",
        //   icon: "mdi-bug",
        //   routeName: "PanelReview",
        //   requiresAdmin: false,
        // },
        // {
        //   text: 'Console Dev',
        //   icon: 'mdi-dev-to',
        //   routeName: 'DevPanel',
        // },
      ],
      navbarItems: [
        { text: 'Campanha', id: 'campaign' },
        { text: 'Rádios', id: 'radios' },
        { text: 'Conteúdo', id: 'content' },
        { text: 'Censura', id: 'censure' }
      ]
    }),

    computed: {
      feedback() {
        return this.$store.getters.getFeedback
      },
      user() {
        return this.$store.getters.getUser
      },
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown
      },
      loading() {
        return this.$store.getters.getLoading
      }
    },

    watch: {
      $route(to) {
        // console.log("route name: ", to.name);
        this.showTabsMenu = false
        if (to.name == 'Home') {
          this.drawerSelectedItem = 0
        } else if (to.name == 'Users') {
          this.drawerSelectedItem = 1
        } else if (to.name == 'Campaigns') {
          this.drawerSelectedItem = 2
        } else if (to.name == 'Campaign') {
          this.drawerSelectedItem = null
          this.showTabsMenu = true
        } else if (to.name == 'AddCampaign') {
          this.drawerSelectedItem = 3
        } else if (to.name == 'Panel') {
          this.drawerSelectedItem = 4
        } else if (to.name == 'Reports') {
          this.drawerSelectedItem = 5
        } else if (to.name == 'DevPanel') {
          this.drawerSelectedItem = 6
        } else {
          this.drawerSelectedItem = null
        }
      }
    },

    methods: {
      doLogout() {
        signOut(auth)
          .then(() => {
            console.log('logout')
            this.$store.dispatch('clearState')
            this.$router.push({ name: 'Login' })
          })
          .catch((error) => {
            console.log(error)
          })
      },
      redirectTo(routeName) {
        if (routeName != this.$route.name) {
          this.$router.push({ name: routeName })
        }
      },
      scrollTo(id) {
        let element = document.getElementById(id)
        if (element) {
          const top = element.offsetTop
          window.scrollTo(0, top)
        }
      }
    }
  }
</script>

<style>
  .nav-menu-item {
    color: white;
    text-decoration: none;
  }
  .nav-bar-menu-item {
    color: #ff5722 !important;
    text-transform: capitalize !important;
  }
  .tab-bottom-style {
    bottom: auto !important;
  }
  .pointer {
    cursor: pointer;
  }
</style>
